@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-Light.ttf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-Medium.ttf') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: Barlow;
  src: url('Assets/Fonts/BarlowCondensed-SemiBold.ttf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Regular.ttf') format('opentype');
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Light.ttf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Medium.ttf') format('opentype');
  font-weight: medium;
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Bold.ttf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: Oswald;
  src: url('Assets/Fonts/Oswald-Bold.ttf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-Black.ttf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-BlackItalic.ttf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-Bold.ttf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-BoldItalic.ttf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-ExtraBold.ttf') format('opentype');
  font-weight: 800;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-ExtraBoldItalic.ttf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-ExtraLight.ttf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-ExtraLightItalic.ttf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-Italic.ttf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-Light.ttf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-LightItalic.ttf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-Medium.ttf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-MediumItalic.ttf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-Regular.ttf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-SemiBold.ttf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: Mulish;
  src: url('Assets/Fonts/Mulish-SemiBoldItalic.ttf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

html,
body {
  font-family: Mulish, Helvetica Neue, Helvetica, Arial, sans-serif;
}

p {
  font-family: Mulish, Arial, sans-serif;
  font-size: 11pt;
  line-height: 1.15;
}

ul,
ol {
  font-family: Mulish, Arial, sans-serif;
  font-size: 11pt;
  line-height: 1.15;
}

li {
  font-family: Mulish, Arial, sans-serif;
  font-size: 11pt;
  line-height: 1.15;
}

strong,
b {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: inherit;
}
